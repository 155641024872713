<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Pacientes</h2>
        <v-divider class="mb-8 mt-2"></v-divider>
        <PatientsList
          :showPatientDialog="false"
          :clickable="true"
          @patient-click="handlePatientClick"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PatientsList from "@/components/Patient/PatientsList";

export default {
  name: "Patients",
  components: {
    PatientsList,
  },
  methods: {
    handlePatientClick(patient) {
      this.$router.push("/paciente/" + patient.id);
    },
  },
};
</script>
